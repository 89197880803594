import React from 'react';
import { Route } from 'react-router-dom';
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
} from '@material-ui/core/styles';
import NavBar from './NavBar';
import PoolDetails from './PoolDetails';
import Footer from './Footer';
import Notifier from './Notifier';
import Drawer from './Drawer';
import PoolsGrid from './PoolsGrid';
import ControlBar from './ControlBar';
import 'hint.css';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Overpass-Regular, sans-serif',
      'Montserrat-Regular, sans-serif',
    ].join(','),
    subtitle1: {
      fontFamily: 'Montserrat-Regular, sans-serif',
    },
  },
  palette: {
    primary: {
      light: '#FAE900',
      main: '#F9C300',
      dark: '#EABA0A',
      contrastText: '#5D4B00',
    },
    secondary: {
      light: '#353535',
      main: '#353535',
      dark: '#353535',
      contrastText: '#f4f4f4',
    },
    background: {
      default: '#000000',
      paper: '#111111',
      red: '#EF5353',
      green: '#11B15E',
      grey: '#6C6C6C',
      main: (opacity = '0.3') => `rgba(250,233,0,${opacity})`,
    },
    text: {
      primary: '#fff',
      secondary: '#fff',
      disabled: '#505050',
      link: '#3498db',
      focusLink: '#1d6fa5',
    },
  },
  overrides: {
    MuiTabs: {
      indicator: {
        height: 0,
      },
      scrollButtons: {
        color: '#FAE900',
        flex: '0 0 24px',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#383838',
        fontSize: '1em',
      },
    },
    MuiFormControl: {
      root: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        flex: '0 0 auto',
        margin: '0.7em 0px',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#000000',
        display: 'flex',
        alignItems: 'center',
        flex: 1,
      },
    },
    MuiInput: {
      root: {
        color: '#000000',
        margin: 0,
        borderRadius: '1em',
        border: '1px solid #505050',
        minWidth: 0,
        minHeight: 0,
        flex: 2,
      },
      input: {
        padding: '0.2em 0.7em',
      },
      multiline: {
        '& div': {
          margin: '0.5em',
        },
      },
      inputMultiline: {
        padding: 0,
      },
      formControl: {
        'label + &': {
          marginTop: 0,
        },
      },
    },
  },
  props: {
    MuiInput: {
      disableUnderline: true, // No more ripple, on the whole application 💣!
    },
    MuiInputLabel: {
      disableAnimation: true,
    },
  },
});

const styles = (theme) => ({
  app: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

const App = ({ classes, match }) => (
  <MuiThemeProvider theme={theme}>
    <div className={classes.app}>
      <Notifier />
      <Drawer />
      <Route component={NavBar} />
      <Route exact path={'/'} component={ControlBar} />
      <Route exact path={'/'} component={PoolsGrid} />
      <Route path={'/pool/:poolOrg/:poolName'} component={PoolDetails} />
      <div className="Spacer" />
      <Footer />
    </div>
  </MuiThemeProvider>
);

export default withStyles(styles)(App);
