const subDomain = '';

export const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? `https://v7.gateway.iex.ec`
    : `https://v7.gateway.iex.ec`;

export const ENTERPRISE_API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? `https://v7.gateway-enterprise.iex.ec`
    : `https://v7.gateway-enterprise.iex.ec`;

export const EXPLORER_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? `https://${subDomain}explorer.iex.ec`
    : `https://${subDomain}explorer.iex.ec`;

export const MARKETPLACE_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? `https://${subDomain}market.iex.ec`
    : `https://${subDomain}market.iex.ec`;

export const DAPPSTORE_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? `https://${subDomain}dapps.iex.ec`
    : `https://${subDomain}dapps.iex.ec`;

export const WORKERPOOLS_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? `https://${subDomain}pools.iex.ec`
    : `https://${subDomain}pools.iex.ec`;

export const IPFS_GATEWAY_URL = 'https://ipfs.iex.ec';

// comment here to remove chain
// default first
export const SUPPORTED_CHAIN_NAMES = ['bellecour'];

export const DEFAULT_CHAIN = 134;

export const DEFAULT_PROVIDER = {
  bellecour: 'https://bellecour.iex.ec',
};
