import React from 'react';
import { connect } from 'react-redux';

import { CSSTransition } from 'react-transition-group';
import { set } from '../store/actions/app';

class FadeOnceComponent extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = { show: false };
  }
  componentDidMount() {
    this.setState({ show: !this.state.show });
    const { incFadeOnceRenderCount } = this.props;
    incFadeOnceRenderCount();
  }
  componentWillUnmount() {
    this.setState({ show: !this.state.show });
  }
  render() {
    const { fadeOnceRenderCount } = this.props;
    const timeout = fadeOnceRenderCount > 1 ? 0 : this.props.timeout;
    return (
      <CSSTransition in={this.state.show} timeout={timeout} classNames="fade">
        {this.props.children}
      </CSSTransition>
    );
  }
}

const mapStateToProps = state => ({
  fadeOnceRenderCount: state.app.fadeOnceRenderCount,
});

const mapDispatchToProps = dispatch => ({
  incFadeOnceRenderCount: () => dispatch(set.incFadeOnceRenderCount()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FadeOnceComponent);
