import Debug from 'debug';
import { all, put, takeEvery } from 'redux-saga/effects';
import { jsonApi } from '../../services/api';
import * as dataActions from '../actions/data';
import { API_BASE_URL } from '../../config';

const debug = Debug('sagas:data');

export function* getPools({ query }) {
  try {
    debug('getPools() query', query);
    const data = yield jsonApi.post({ api: API_BASE_URL, endpoint: '/pools' });
    yield put(dataActions.getPoolsAsync.success(data.pools));
  } catch (error) {
    yield put(dataActions.getPoolsAsync.failure(error.message));
  }
}

// SAGAS WATCHERS
export function* watchData() {
  yield takeEvery('GET_POOLS_REQUEST', getPools);
}

export default function* dataSaga() {
  yield all([watchData()]);
}
