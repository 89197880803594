import { combineReducers } from 'redux';

function pools(state = [], action) {
  switch (action.type) {
    case 'GET_POOLS_SUCCESS':
      return [...action.pools];
    default:
      return state;
  }
}

export default combineReducers({
  pools,
});
