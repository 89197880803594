import React from 'react';
import Debug from 'debug';
import { connect } from 'react-redux';
import copy from 'copy-to-clipboard';
import { mediaQueries } from '../utils/display';
import { getChainKey, isSupportedChain } from '../utils/chain';
import { toURL } from '../utils/format';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import * as notifierActions from '../store/actions/notifier';
const debug = Debug('PoolDetails');

const styles = (theme) => ({
  root: {
    color: theme.palette.text.primary,
    display: 'flex',
    margin: '20px',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
  spacer: {
    height: '10px',
  },
  leftBox: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginBottom: '30px',
    minWidth: '150px',
  },
  rightBox: {
    display: 'flex',
    flex: 4,
    flexDirection: 'column',
    marginBottom: '20px',
  },
  logo: {
    position: 'relative',
  },
  logoIMG: {
    borderRadius: '10px',
    height: '100px',
    width: '100px',
  },
  goBadge: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '25px',
    cursor: 'pointer',
    display: 'flex',
    height: '30px',
    justifyContent: 'center',
    width: '100px',
    '& p': {
      color: theme.palette.primary.contrastText,
      fontSize: '10px',
      fontWeight: 'bold',
      transform: 'translateY(2px)',
    },
  },
  nameBox: {
    fontWeight: 'bold',
  },
  nameBoxSpan: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    marginRight: '10px',
  },
  author: {
    fontWeight: 800,
  },
  social: {
    fontSize: '1.5em',
    marginTop: '20px',
  },
  socialIcon: {
    margin: '5px',
  },
  website: {
    transform: ' translateY(2px)',
  },
  linkedin: {
    color: '#1a80b9',
  },
  twitter: {
    color: '#55acee',
  },
  chain: {
    margin: '5px',
  },
  clipboard: {
    cursor: 'copy',
  },
  description: {
    marginTop: '20px',
    maxWidth: '650px',
    textAlign: 'justify',
  },
  [mediaQueries.mobile.down]: {
    root: {
      flexDirection: 'column',
    },
    leftBox: {
      alignItems: 'center',
      flexDirection: 'row',
      minWidth: 0,
      margin: '0 auto 2em auto',
    },
    rightBox: {
      alignItems: 'center',
    },
  },
});

const PoolDetails = ({ match, notify, isLoading, pool, classes }) => {
  debug('pool', pool);
  if (pool) {
    return (
      <div className={classes.root}>
        <div className={classes.leftBox}>
          <div>
            <div className={classes.logo}>
              <img
                src={pool.logo}
                alt={pool.name}
                className={classes.logoIMG}
              />
            </div>
            <div className={classes.spacer} />
            {pool.social && pool.social.website && (
              <a
                href={pool.social.website}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.goBadge}
              >
                <p>See More</p>
              </a>
            )}
          </div>
        </div>

        <div className={classes.rightBox}>
          <div className={classes.nameBox}>
            <span className={classes.nameBoxSpan}>{pool.name}</span>
          </div>

          {pool.addresses && (
            <div className={classes.social}>
              {['Addresses: '].concat(
                Object.keys(pool.addresses).map((e) => {
                  if (!isSupportedChain(e)) return '';
                  return (
                    <div
                      className={classNames(
                        classes.chain,
                        classes.clipboard,
                        'hint--top',
                      )}
                      key={e}
                      aria-label="click to copy the pool address"
                      onClick={() => {
                        copy(pool.addresses[e]);
                        notify({
                          message: `address copied to clipboard`,
                        });
                      }}
                    >
                      #{getChainKey(e)}
                    </div>
                  );
                }),
              )}
            </div>
          )}

          <div className={classes.description}>{pool.description}</div>
        </div>
      </div>
    );
  }
  if (isLoading['GET_GETPOOLS']) return <div className={classes.root} />;
  return (
    <div
      className={classes.root}
    >{`Sorry! "${match.params.poolOrg}/${match.params.poolName}" workerpool not found!`}</div>
  );
};

const mapStateToProps = (state, { match }) => {
  const pool = state.data.pools.find(
    (e) =>
      match.params.poolOrg === toURL(e.org) &&
      match.params.poolName === toURL(e.name),
  );
  return {
    isLoading: state.isLoading,
    pool,
  };
};

const mapDispatchToProps = (dispatch) => ({
  notify: (params) => dispatch(notifierActions.notify(params)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(PoolDetails),
);
