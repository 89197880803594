import React from 'react';
// import Debug from 'debug'
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { set } from '../store/actions/app';
import Searcher from './Searcher';
import { mediaQueries } from '../utils/display';

// const debug = Debug('FiltersBar')

const styles = theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    flexShrink: 0,
    margin: 10,
    [mediaQueries.mobile.down]: {
      alignItems: 'stretch',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
  },
});

const ControlBar = ({
  classes,
  selectedCategory,
  selectCategory,
  searcherValue,
  searchValue,
  categories,
}) => (
  <div className={classes.root}>
    <Searcher value={searcherValue} onChange={searchValue} />
  </div>
);

const mapStateToProps = state => ({
  searcherValue: state.app.searcherValue,
});

const mapDispatchToProps = dispatch => ({
  searchValue: value => dispatch(set.searchValue(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ControlBar));
