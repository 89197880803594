import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaBars } from 'react-icons/fa';
import { FaUpload } from 'react-icons/fa';
import { withStyles } from '@material-ui/core/styles';
import { set } from '../store/actions/app';
import { getPoolsAsync } from '../store/actions/data';
import navBarLogo from '../assets/images/iexec-logo.svg';
import Button from './Button';
import { mediaQueries } from '../utils/display';

const styles = theme => ({
  root: {
    'grid-area': 'header',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    flexShrink: 0,
  },
  leftBox: {
    display: 'flex',
  },
  logoBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '1em',
  },
  logo: {
    height: 'auto',
    width: '6em',
  },
  title: {
    fontSize: '1.3em',
    fontWeight: 'bold',
    marginLeft: '10px',
    color: theme.palette.text.primary,
  },
  rightBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  bars: {
    cursor: 'pointer',
    fontSize: '1.5em',
    color: theme.palette.text.primary,
    padding: 20,
  },
  loginText: {
    fontSize: '1.3em',
  },
  submit: {
    color: theme.palette.primary.contrastText,
    marginLeft: 10,
    fontWeight: 'normal',
    [mediaQueries.medium.down]: {
      padding: '0.6em',
    },
    '& span': {
      [mediaQueries.medium.down]: {
        display: 'none',
      },
    },
  },
  uploadIcon: {
    margin: '0 5px',
  },
});

class NavBar extends Component {
  componentDidMount() {
    const { getPools } = this.props;
    getPools({});
    // this.props.setPools(poolsSample)
  }
  render() {
    const { classes, toggleDrawer } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.leftBox}>
          <Link className={classes.logoBox} to={'/'}>
            <img className={classes.logo} src={navBarLogo} alt="iExec logo" />
            <div className={classes.title}> Workerpools </div>
          </Link>
        </div>
        <div className={classes.rightBox}>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={
              'https://github.com/iExecBlockchainComputing/iexec-pools-registry'
            }
          >
            <Button className={classes.submit}>
              <div>
                <span>New Workerpool</span>{' '}
                <FaUpload className={classes.uploadIcon} />
              </div>
            </Button>
          </a>
          <div
            className={classes.bars}
            onClick={() => toggleDrawer()}
            onKeyDown={() => toggleDrawer()}
          >
            <FaBars />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  getPools: query => dispatch(getPoolsAsync.request(query)),
  toggleDrawer: () => dispatch(set.toggleDrawer()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(NavBar));
