import { SUPPORTED_CHAIN_NAMES } from '../config';

const chains = {
  mainnet: {
    name: 'mainnet',
    id: 1,
    desc: 'Ethereum Mainnet',
    bridgedChain: 'bellecour',
    bridgeAddress: '0x4e55c9B8953AB1957ad0A59D413631A66798c6a2',
  },
  bellecour: {
    name: 'bellecour',
    id: 134,
    isNative: true,
    desc: 'iExec Sidechain',
    bridgedChain: 'mainnet',
    bridgeAddress: '0x188A4376a1D818bF2434972Eb34eFd57102a19b7',
  },
};
chains['1'] = chains.mainnet;
chains['134'] = chains.bellecour;

const chainKeys = {
  1: 'mainnet',
  134: 'bellecour',
};

export const isSupportedChain = (chainKey) =>
  !!chains[chainKey] && SUPPORTED_CHAIN_NAMES.includes(chains[chainKey].name);

export const getChainKey = (chainId) => chainKeys && chainKeys[chainId];
