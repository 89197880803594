import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { mediaQueries } from '../utils/display';
import { toURL } from '../utils/format';
import dotsSVG from '../assets/images/dots.svg';

const LinkSwitch = ({ to, children }) => {
  if (to.includes('://'))
    return (
      <a target="_blank" rel="noreferrer noopener" href={to}>
        {children}
      </a>
    );
  return <Link to={to}>{children}</Link>;
};

const styles = theme => ({
  poolCard: {
    backgroundColor: '#131313',
    backgroundImage: `url(${dotsSVG})`,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    position: 'relative',
    '& a': {
      color: theme.palette.text.secondary,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      textDecoration: 'none',
    },
    [mediaQueries.mobile.down]: {
      height: '100%',
      width: '90%',
    },
  },
  diamond: {
    margin: '15%',
    width: '84.6px' /* 60px/sqrt(2) */,
    height: '84.6px' /* 60px/sqrt(2) */,
    [mediaQueries.mobile.down]: {
      height: '141px' /* 100px*sqrt(2) */,
      width: '141px' /* 100px*sqrt(2) */,
    },
    alignSelf: 'center',
    '&> div': {
      boxSizing: 'border-box',
      padding: '14.53%',
      width: '100%',
      height: '100%',
      '&> div': {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        '-ms-transform': 'rotate(45deg)' /* Internet Explorer */,
        '-moz-transform': 'rotate(45deg)' /* Firefox */,
        '-webkit-transform': 'rotate(45deg)' /* Safari et Chrome */,
        '-o-transform': 'rotate(45deg)' /* Opera */,
        borderRadius: '10px',
        '&> img': {
          width: '141%',
          height: '141%',
          margin: '-20.5%',
          '-ms-transform': 'rotate(-45deg)' /* Internet Explorer */,
          '-moz-transform': 'rotate(-45deg)' /* Firefox */,
          '-webkit-transform': 'rotate(-45deg)' /* Safari et Chrome */,
          '-o-transform': 'rotate(-45deg)' /* Opera */,
        },
      },
    },
  },
  name: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    [mediaQueries.mobile.down]: {
      fontSize: '1.5em',
    },
  },
  spacer: {
    display: 'flex',
    flexGrow: 1,
  },
});

const PoolCard = ({ classes, pool }) => {
  return (
    <div className={classes.poolCard}>
      <LinkSwitch to={`/pool/${toURL(pool.org)}/${toURL(pool.name)}`}>
        <div className={classes.diamond}>
          <div>
            <div>
              <img src={pool.logo} alt={pool.name} />
            </div>
          </div>
        </div>
        <div className={classes.name}>{pool.name}</div>
        <div className={classes.spacer} />
      </LinkSwitch>
    </div>
  );
};

export default withStyles(styles)(PoolCard);
