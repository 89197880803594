import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PoolCard from './PoolCard';
import FadeOnce from './FadeOnce';
import { mediaQueries } from '../utils/display';

const styles = theme => ({
  poolsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridAutoRows: '350px',
    gridGap: '2rem',
    justifyContent: 'space-around',
    alignContent: 'flex-start',
    justifyItems: 'center',
    alignItems: 'center',
    padding: '10px',
    [mediaQueries.mobile.up]: {
      gridTemplateColumns: 'repeat(auto-fill, 190px)',
      gridAutoRows: '220px',
    },
  },
});

const PoolsGrid = ({ classes, pools, searcherValue }) => {
  const matchSearchPools = pools.filter(e =>
    e.name.toLowerCase().includes(searcherValue.toLowerCase()),
  );
  return (
    <FadeOnce timeout={1000}>
      <div className={classes.poolsGrid}>
        {matchSearchPools.map((e, i) => (
          <PoolCard pool={e} key={i} />
        ))}
      </div>
    </FadeOnce>
  );
};

const mapStateToProps = state => ({
  pools: state.data.pools.sort((a, b) => {
    if (a.created < b.created) return 1;
    return -1;
  }),
  searcherValue: state.app.searcherValue,
});

export default connect(mapStateToProps)(withStyles(styles)(PoolsGrid));
